import React, {Component} from "react"
import styles from "./Footer.module.css"
import ahtLogo from "../Header/parts/img/logo-aht-black.png"

const campuses = [
    {
        id: 1,
        region_id: 1,
        name: "South Carolina",
        code: "South Carolina",
        address: "3715 E North St, Greenville, SC 29615",
        state: "",
        phone: "864-322-0300",
        school_code: "",
        region: {
            id: 1,
            state: "South Carolina",
            region: "South Carolina"
        }
    }
]

const social = [
    {
        id: 1,
        name: "Facebook",
        link: "https://www.facebook.com/AcademyofHairTechnologySC",
        icon: "fa fa-facebook"
    },
    {
        id: 2,
        name: "Twitter",
        link: "https://twitter.com/HairTech_SC",
        icon: "fa fa-twitter"
    },
    {
        id: 3,
        name: "Youtube",
        link: "https://www.youtube.com/user/HairTechSC",
        icon: "fa fa-youtube-play"
    },
    {
        id: 4,
        name: "Pinterest",
        link: "https://www.pinterest.com/hairchamps/",
        icon: "fa fa-pinterest"
    }
]

interface Props {}

export class Footer extends Component<Props, {}> {
    public render() {
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <div>
                        <div>
                            <img src={ahtLogo} alt="aht-logo" className={styles.logo} />
                        </div>
                        <div className={styles.social}>
                            {social.map(({id, link, icon}) => (
                                <a
                                    href={link}
                                    className={icon}
                                    key={id}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {""}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>CAMPUS</strong>
                        </h5>
                        {campuses.map(({code, address, phone, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.code}>
                                    <strong>{code}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </footer>
        )
    }
}
